// assets
import {
  IconDashboard,
  IconUsers,
  IconCalendarTime,
  IconBed,
  IconFileInvoice,
  IconReportAnalytics,
  IconSettings,
  IconTestPipe,
  IconCertificate,
  IconPlus,
  IconCurrencyRupee,
} from "@tabler/icons";

// constant
const icons = {
  IconDashboard,
  IconUsers,
  IconCalendarTime,
  IconBed,
  IconFileInvoice,
  IconReportAnalytics,
  IconSettings,
  IconTestPipe,
  IconCertificate,
  IconPlus,
  IconCurrencyRupee,
};

// ==============================|| CUSTOMER MENU ITEMS ||============================== //

const frontDeskMenuItems = [
  {
    id: "patients-section",
    type: "group",
    children: [
      {
        id: "patients",
        title: "Patients",
        type: "item",
        url: "/patients",
        icon: icons.IconUsers,
        breadcrumbs: false,
      },
      {
        id: "filtered-patients",
        title: "Filtered Patients",
        type: "item",
        url: "/filtered-patients",
        icon: icons.IconUsers,
        breadcrumbs: false,
      },
    ],
  },
  {
    id: "fd-main-pages",
    type: "group",
    children: [
      {
        id: "appointments",
        title: "Appointments",
        type: "item",
        icon: icons.IconCalendarTime,
        url: "/appointments",
        breadcrumbs: false,
      },
      // {
      //     id: 'ipd',
      //     title: 'IPD',
      //     type: 'collapse',
      //     icon: icons.IconBed,
      //     breadcrumbs: false,
      //     children: [
      //         {
      //             id: 'ipd-overview',
      //             title: 'IPD Overview',
      //             type: 'item',
      //             url: '/ipd-overview',
      //             breadcrumbs: false
      //         }
      //     ]
      // },
      {
        id: "investigations",
        title: "Investigations",
        type: "item",
        url: "/investigations",
        icon: icons.IconTestPipe,
        breadcrumbs: false,
      },
      // {
      //   id: "additional-treatment",
      //   title: "Additional Treatmant",
      //   type: "item",
      //   url: "/additional-treatment",
      //   icon: icons.IconPlus,
      //   breadcrumbs: false,
      // },
      {
        id: "daycare-overview",
        title: "Day Care Overview",
        type: "item",
        icon: icons.IconBed,
        url: "/daycare-overview",
        breadcrumbs: false,
      },
      {
        id: "billing",
        title: "Billing",
        type: "collapse",
        icon: icons.IconFileInvoice,
        breadcrumbs: false,
        children: [
          // {
          //   id: "advance-payments",
          //   title: "Advance Payments",
          //   type: "item",
          //   url: "/advance-payments",
          //   breadcrumbs: false,
          // },
          //   {
          //     id: "ipd-billing",
          //     title: "IPD Bills",
          //     type: "item",
          //     url: "/billing",
          //     breadcrumbs: false,
          //   },
          {
            id: "opdReceipts",
            title: "OPD Receipts/Bills",
            type: "item",
            url: "/opd-receipts",
            breadcrumbs: false,
          },

          // {
          //   id: "discharge-cards",
          //   title: "Discharge Cards",
          //   type: "item",
          //   url: "/discharge-cards",
          //   breadcrumbs: false,
          // },
        ],
      },
      {
        id: "operating-expenses",
        title: "Expenses",
        type: "item",
        url: "/operating-expenses",
        icon: icons.IconCurrencyRupee,
        breadcrumbs: false,
      },
      {
        id: "certificates",
        title: "Certificates",
        type: "item",
        url: "/certificates",
        icon: icons.IconCertificate,
        breadcrumbs: false,
      },
    ],
  },
  {
    id: "main-settings",
    type: "group",
    children: [
      {
        id: "setting-pages",
        title: "Settings",
        type: "collapse",
        icon: icons.IconSettings,
        breadcrumbs: false,
        children: [
          {
            id: "expense-category-item",
            title: "Expenses Categories",
            type: "item",
            url: "/expense-category-item-master",

            breadcrumbs: false,
          },
          {
            id: "opd-receipt-item",
            title: "OPD Receipt Items",
            type: "item",
            url: "/opd-receipt-item-master",

            breadcrumbs: false,
          },
          {
            id: "refferedBy",
            title: "Referral Doctors",
            type: "item",
            url: "/refferedBy",

            breadcrumbs: false,
          },
        ],
      },
    ],
  },
  {
    id: "report-pages",
    type: "group",
    children: [
      {
        id: "reports-pages",
        title: "Reports",
        type: "collapse",
        icon: icons.IconSettings,
        breadcrumbs: false,
        children: [
          {
            id: "revenue-report",
            title: "Revenue Report",
            type: "item",
            url: "/revenue-report",
            breadcrumbs: false,
          },
        ],
      },
    ],
  },
];

export default frontDeskMenuItems;
