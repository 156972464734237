import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  Stack,
  Typography,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";

// project imports
import LogoSection from "../LogoSection";
import SearchSection from "./SearchSection";
import ProfileSection from "./ProfileSection";
import appLogo from "assets/images/app-logo.jpg";
// import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from "@tabler/icons";

import React, { useEffect, useState } from "react";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const { profileId, role, userName, roleDisplay } = useSelector(
    (state) => state.userReducer
  );

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [open, setOpen] = React.useState(false);

  const showInternetError = () => {
    if (!isOnline) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    showInternetError();
  }, [isOnline]);

  // Handle online/offline events to update the isOnline state
  const handleConnectionChange = () => {
    setIsOnline(navigator.onLine);
  };

  // Set up event listeners for online/offline events
  window.addEventListener("online", handleConnectionChange);
  window.addEventListener("offline", handleConnectionChange);

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">
          <Typography variant="h4" color="white">
            Oops! You are offline
          </Typography>
          <Typography variant="h5" color="white">
            Please check your internet connection and kindly refresh this page
            after a successful network connection
          </Typography>
        </Alert>
      </Snackbar>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 320,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{
            display: { xs: "none", sm: "flex" },
            alignItems: "center",
            marginRight: { xs: 0, sm: 5, md: 0 },
          }}
        >
          <img src={appLogo} alt="Logo" width="110" />
        </Box>
        <ButtonBase
          sx={{ borderRadius: "12px", overflow: "hidden", margin: "auto" }}
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      {/* header search
            <SearchSection />*/}
      <Box sx={{ flexGrow: 1 }} />
      <Box
        component="span"
        sx={{
          display: { xs: "none", md: "flex" },
          alignItems: "center",
        }}
      >
        <LogoSection />
        <Stack>
          <Typography sx={{ marginLeft: 2, fontWeight: 900, fontSize: 15 }}>
            SUSHIL IVF
          </Typography>
          <Typography
            sx={{
              marginLeft: 2,
              fontWeight: 900,
              fontSize: 12,
              marginTop: 0.1,
              color: theme.palette.grey[600],
            }}
          >
            SANGLI
          </Typography>
        </Stack>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      <Stack direction="row" spacing={2} alignItems="center">
        <Stack>
          <Typography variant="h5">
            Hello, {role == 0 && "Dr."} {userName}
          </Typography>
          <Typography variant="caption">{roleDisplay}</Typography>
        </Stack>
        <ProfileSection />
      </Stack>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
