import React, { useContext, useState, useEffect, useRef } from "react";
import PrintReportContext from "./ReportContext";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
//Third Party Imports
import { useReactToPrint } from "react-to-print";
import {
  Grid,
  Box,
  Typography,
  Card,
  useTheme,
  CardHeader,
  Stack,
  ButtonGroup,
  Tooltip,
  Button,
  Divider,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import ReportCardData from "./ReportCardData";
import PrintReport from "./PrintReport";
const ReportContent = () => {
  const { reportdata, reportName, reportId, doctorsdata, patientname, theme } =
    useContext(PrintReportContext);
  //create Referance
  const componentRef = useRef();

  const [printCmd, setPrintCmd] = useState(false);
  useEffect(() => {
    if (printCmd) {
      handlePrint();
    }
  }, [printCmd]);
  const onPrintComplete = () => {
    setPrintCmd(false);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: onPrintComplete,
  });

  return (
    <>
      <Card
        variant="outlined"
        sx={{ marginTop: 2, backgroundColor: theme.palette.grey[50] }}
      >
        <Typography
          textAlign="center"
          variant="h3"
          color="primary"
          sx={{ marginY: 2 }}
        >
          {reportName} Report
        </Typography>

        <Grid container justifyContent="flex-end">
          <Grid item xs={2} sx={{ marginRight: 5 }}>
            <Tooltip title="Print Vital Chart">
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  setPrintCmd(true);
                }}
              >
                {`Print  `}
                <LocalPrintshopRoundedIcon />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <CardHeader
          title={
            <Stack direction="row" alignItems="center">
              <Typography variant="h4" sx={{ marginRight: 1 }}>
                Patient Name:
              </Typography>
              <Typography variant="h4" sx={{ marginRight: 3 }}>
                {patientname?.patientMaster?.firstName}{" "}
                {patientname?.patientMaster?.lastName}
              </Typography>
            </Stack>
          }
        />

        <Divider />
        <CardContent>
          <ReportCardData ref={componentRef} />
        </CardContent>
      </Card>
      <div style={{ display: "none" }}>
        <PrintReport ref={componentRef} />
      </div>
    </>
  );
};

export default ReportContent;
