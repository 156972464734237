import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  LinearProgress,
  Tabs,
  Tab,
} from "@mui/material";
import NoReport from "../../../../ui-component/formcomponent/NoReport";
import useGrowthAltraSoundScanService from "services/useGrowthAltrasoundScanService";
import ReportCard from "./ReportCard";
import { ta } from "date-fns/locale";
import useSonographyService from "services/useSonographyReport";
import useViabilityReportService from "services/useViabilityReport";

const ExternalReport = () => {
  const navigate = useNavigate();
  const { getById } = useGrowthAltraSoundScanService();
  const { getSonographyByPatientId } = useSonographyService();
  const { getViabilityReportByPatientId } = useViabilityReportService();
  const [searchParams, setSearchParams] = useSearchParams();
  const [reportData, setReportData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const OpenUltraSoundForm = () => {
    if (tabValue == 0) {
      navigate(`/sonography-form?pateintId=${searchParams.get("Id")}`);
    }
    if (tabValue == 1) {
      navigate(`/viability-form?pateintId=${searchParams.get("Id")}`);
    }
    if (tabValue == 2) {
      navigate(`/growthultrasound-form?pateintId=${searchParams.get("Id")}`);
    }
  };
  const loadData = async () => {
    let growthReport = {};
    let sonogrphyReport = {};
    let viabilityReport = {};
    try {
      const result = await getById(searchParams.get("Id"));
      if (result.data?.count > 0) {
        growthReport.name = "growth";
        growthReport.data = result.data?.growthUltrasoundScans;
      }
    } catch (err) {
      console.log(err);
    }
    try {
      const resultsonography = await getSonographyByPatientId(
        searchParams.get("Id")
      );

      if (resultsonography.data.count > 0) {
        sonogrphyReport.name = "sonography";
        sonogrphyReport.data = resultsonography.data?.sonographies;
      }
    } catch (err) {
      console.log(err);
    }
    try {
      const resultViability = await getViabilityReportByPatientId(
        searchParams.get("Id")
      );
      if (resultViability?.data?.count > 0) {
        viabilityReport.name = "viability";
        viabilityReport.data = resultViability?.data?.viabilityScans;
      }
    } catch (err) {
      console.log(err);
    }

    if (growthReport.name || viabilityReport.name || sonogrphyReport.name) {
      setReportData([growthReport, viabilityReport, sonogrphyReport]);
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  const handleTabChange = (e, newVal) => {
    setTabValue(newVal);
  };
  return (
    <>
      <Grid container direction="column" sx={{ mt: 2 }}>
        <Grid item sm={6} xs={6}>
          <Typography align="left" variant="h4" color="secondary">
            External Reports
          </Typography>
        </Grid>
        <Box sx={{}}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Sonography Report"></Tab>
            {/* <Tab label="Viability Scan Report"></Tab>
            <Tab label="External Growth Scan Report"></Tab> */}
          </Tabs>
        </Box>
        <Grid item align="right" sm={6} xs={6}>
          <Button onClick={OpenUltraSoundForm} variant="contained">
            Add New Report
          </Button>
        </Grid>
        {reportData.length > 0 ? (
          <ReportCard reportData={reportData} loadData={loadData} />
        ) : (
          <NoReport />
        )}
      </Grid>
    </>
  );
};

export default memo(ExternalReport);
