import React, { createContext, useEffect, useState } from "react";
import { Grid, Typography, Box, Tabs, Tab, Button } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DialogContextProvider, useDialogOpenration } from "./DialogContext";
import DialogComponent from "views/patients/patient-details/doctores-reports/Dialog";
import LaproScopyForm from "./LaproScopyReport";
import HysterolaproscopyForm from "./HysterolaproscopyReport";
import LummellaForm from "./LummelaReport";
import useLaporoscopyServices from "services/useLaporoscopyServices";
import useHysterolaproscopyServices from "services/useHysterolaproscopyServices";
import useLummellaServices from "services/useLummellaServices";
import ReportCard from "./ReportCard";
import NoReport from "ui-component/formcomponent/NoReport";
const DoctorsReport = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [searchParams] = useSearchParams();

  //all report data
  const [reportData, setReportData] = useState([]);

  //call services
  const { getLaproscopyReportByPatientId, getLaproscopyReportByReportId } =
    useLaporoscopyServices();
  const {
    getHysterolaproscopyReportByPatientId,
    getHysterolaproscopyReportByReportId,
  } = useHysterolaproscopyServices();
  const { getLummellaReportByPatientId, getLummellaReportByReportId } =
    useLummellaServices();
  const handleTabChange = (e, newVal) => {
    setTabValue(newVal);
  };
  const OpenForm = () => {
    if (tabValue == 0) {
      navigate(`/laproscopy-form?pateintId=${searchParams.get("Id")}`);
    }
    if (tabValue == 1) {
      navigate(`/hysterolaproscopy-form?pateintId=${searchParams.get("Id")}`);
    }
    if (tabValue == 2) {
      navigate(`/lummella-form?pateintId=${searchParams.get("Id")}`);
    }
  };

  const loadAllReportData = async () => {
    const laproscopyReport = {};
    const hysterolaproscopyReport = {};
    const lummelaReport = {};
    try {
      const result = await getLaproscopyReportByPatientId(
        searchParams.get("Id")
      );

      if (result.data.count > 0) {
        {
          laproscopyReport.name = "Laproscopy";
          laproscopyReport.data = result.data?.laproscopies;
        }
      }
    } catch (err) {
      console.log(err);
    }
    try {
      const result = await getHysterolaproscopyReportByPatientId(
        searchParams.get("Id")
      );

      if (result.data.count > 0) {
        hysterolaproscopyReport.name = "Hysterolaproscopy";
        hysterolaproscopyReport.data = result.data?.hysterolaproscopies;
      }
    } catch (err) {
      console.log(err);
    }
    try {
      const result = await getLummellaReportByPatientId(searchParams.get("Id"));
      if (result?.data?.count > 0) {
        hysterolaproscopyReport.name = "Lummella";
        hysterolaproscopyReport.data = result.data?.Lummella;
      }
    } catch (err) {
      console.log(err);
    }
    if (
      laproscopyReport.name ||
      hysterolaproscopyReport.name ||
      lummelaReport.name
    ) {
      setReportData([laproscopyReport, hysterolaproscopyReport, lummelaReport]);
    }
  };
  useEffect(() => {
    loadAllReportData();
  }, []);

  return (
    <Grid container direction="column" sx={{ mt: 2 }}>
      <Grid item sm={6} xs={6}>
        <Typography align="left" variant="h4" color="secondary">
          Doctor's report
        </Typography>
      </Grid>
      <Box sx={{}}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Laproscopy Report"></Tab>
          <Tab label="Hysterolaproscopy Report"></Tab>
          {/* <Tab label="Lummella Report"></Tab> */}
        </Tabs>
      </Box>
      <Grid item align="right" sm={6} xs={6}>
        <Button onClick={OpenForm} variant="contained">
          Add New Report
        </Button>
      </Grid>

      {reportData.length > 0 ? (
        <ReportCard
          loadAllReportData={loadAllReportData}
          reportData={reportData}
        />
      ) : (
        <NoReport />
      )}
      {/* <DialogComponent
        name={
          tabValue == 0
            ? "LaproScopy"
            : tabValue == 1
            ? "Hysterolaproscopy"
            : "Lummella"
        }
      >
        {tabValue == 0 ? (
          <LaproScopyForm />
        ) : tabValue == 1 ? (
          <HysterolaproscopyForm />
        ) : (
          <LummellaForm />
        )}
      </DialogComponent> */}
    </Grid>
  );
};

export default DoctorsReport;
