import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Grid, Typography, Button, Stack, Card } from "@mui/material";
import FormTextInput from "ui-component/formcomponent/FormTextInput";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";

import { useNavigate } from "react-router-dom";
import useLaporoscopyServices from "services/useLaporoscopyServices";
import NoPhotosComponent from "ui-component/formcomponent/NoPhoto";
import UploadPhoto from "../UploadPhoto";
import useLaproscopyPhotosServices from "services/useLaproscopyPhotosServices";
const initialValue = {
  patientId: 0,
  userId: 0,
  cervix: 0,
  cervialCanal: 0,
  uterineCavity: 0,
  biCornue: 0,
  endometrium: 0,
  uterus: "",
  overy: "",
  rightOvery: "",
  leftOvery: "",
  tubes: "",
  tubalPatencyTest: "",
  dischargeDate: new Date().toISOString().split("T")[0],
  comments: "",
};
const LaproScopyForm = () => {
  const navigate = useNavigate();
  //current logged user
  const { userId: UserId } = useSelector((state) => state.userReducer);
  //for onsubmit required phototlist which is present in uploadpohot
  const [uploadphotoList, setUpoloadPhotoList] = useState();

  //search prameters of url
  const [searchParams] = useSearchParams();

  //state for store photo file

  //services
  const {
    addLaproscopyReport,
    updateLaproscopyReport,
    getLaproscopyReportByReportId,
  } = useLaporoscopyServices();
  const { addLaproscopyReportPhoto } = useLaproscopyPhotosServices();
  const { setValue, getValues, watch, reset, control, handleSubmit } = useForm({
    defaultValues: initialValue,
    mode: "all",
  });

  useEffect(() => {
    if (!UserId) {
      return;
    }
    setValue("userId", UserId);
    if (searchParams.get("pateintId")) {
      setValue("patientId", searchParams.get("pateintId"));
    }
    if (searchParams.get("reportid")) {
      getLaproscopyReportByReportId(searchParams.get("reportid")).then(
        (result) => {
          console.log(result?.data);
          if (result.data.dischargeDate) {
            result.data.dischargeDate = result.data.dischargeDate.split("T")[0];
          }
          reset(result.data);
        }
      );
    }
  }, []);
  const handleCancle = () => {
    console.log("patientId", getValues("patientId"));
    navigate(`/patient-details?Id=${getValues("patientId")}&tabValue=${2}`);
  };
  console.log("Laproscopy :", watch());
  const onSubmit = async (data) => {
    if (searchParams.get("reportid")) {
      try {
        const result = await updateLaproscopyReport(
          searchParams.get("reportid"),
          data
        );
        const formData = new FormData();

        Array.isArray(uploadphotoList) &&
          uploadphotoList.map(async (file) => {
            formData.append("file", file);
            try {
              const response = await addLaproscopyReportPhoto(
                searchParams.get("reportid"),
                formData
              );
            } catch (err) {
              console.log(err);
            }
          });

        navigate(`/patient-details?Id=${data.patientId}&tabValue=${2}`);
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const result = await addLaproscopyReport(data);
        const reportid = result?.data?.newId;
        const formData = new FormData();
        if (reportid && uploadphotoList?.length > 0) {
          uploadphotoList.map(async (file) => {
            formData.append("file", file);
            try {
              const response = await addLaproscopyReportPhoto(
                reportid,
                formData
              );
            } catch (err) {
              console.log(err);
            }
          });
        }
        navigate(`/patient-details?Id=${data.patientId}&tabValue=${2}`);
      } catch (err) {
        console.log(err);
      }
    }
  };
  //photo add update
  const takePhotoList = (photodata) => {
    setUpoloadPhotoList(photodata);
  };
  return (
    <>
      <Card padding={5}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            padding={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography align="center" variant="h3" marginTop={2}>
                Laproscopy Report
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={8}>
                  <Typography align="left" variant="h4">
                    Discharge Date
                  </Typography>
                  <FormTextInput
                    control={control}
                    type="date"
                    name="dischargeDate"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormTextInput
                    control={control}
                    name="comments"
                    type="text"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography align="left" variant="h4" color="secondary">
                Hysteroscopic Findings
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid xs={8}>
                  <Typography align="left" variant="h4" padding={1}>
                    Cervix
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant={
                          getValues("cervix") == 0 ? "contained" : "outlined"
                        }
                        onClick={() => {
                          setValue("cervix", 0);
                        }}
                      >
                        Healthy
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant={
                          getValues("cervix") == 1 ? "contained" : "outlined"
                        }
                        onClick={() => {
                          setValue("cervix", 1);
                        }}
                      >
                        UnHealthy
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid xs={8}>
                  <Typography align="left" variant="h4" padding={1}>
                    Cervial Canal
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant={
                          getValues("cervialCanal") == 0
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          setValue("cervialCanal", 0);
                        }}
                      >
                        Normal
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant={
                          getValues("cervialCanal") == 1
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          setValue("cervialCanal", 1);
                        }}
                      >
                        Abnormal
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid xs={8}>
                  <Typography align="left" variant="h4" padding={1}>
                    Uterine Cavity
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant={
                          getValues("uterineCavity") == 0
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          setValue("uterineCavity", 0);
                        }}
                      >
                        Normal
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant={
                          getValues("uterineCavity") == 1
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          setValue("uterineCavity", 1);
                        }}
                      >
                        Abnormal
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid xs={8}>
                  <Typography align="left" variant="h4" padding={1}>
                    B/I Cornue
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant={
                          getValues("biCornue") == 0 ? "contained" : "outlined"
                        }
                        onClick={() => {
                          setValue("biCornue", 0);
                        }}
                      >
                        Visible
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant={
                          getValues("biCornue") == 1 ? "contained" : "outlined"
                        }
                        onClick={() => {
                          setValue("biCornue", 1);
                        }}
                      >
                        NotVisible
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Grid container>
                    <Grid xs={8}>
                      <Typography align="left" variant="h4" padding={1}>
                        Endometrium
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Button
                            fullWidth
                            variant={
                              getValues("endometrium") == 0
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              setValue("endometrium", 0);
                            }}
                          >
                            Healthy
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            fullWidth
                            variant={
                              getValues("endometrium") == 1
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              setValue("endometrium", 1);
                            }}
                          >
                            UnHealth
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography align="left" variant="h4" color="secondary">
                Laproscopic Findings
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2}>
                <Grid container alignItems="center">
                  <Grid item xs={8}>
                    <Typography align="left" variant="h4" padding={1}>
                      Uterus
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormTextInput
                      control={control}
                      name="uterus"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={8}>
                    <Typography align="left" variant="h4" padding={1}>
                      Overy
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormTextInput
                      control={control}
                      name="overy"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={8}>
                    <Typography align="left" variant="h4" padding={1}>
                      RightOvery
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormTextInput
                      control={control}
                      name="rightOvery"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={8}>
                    <Typography align="left" variant="h4" padding={1}>
                      LeftOvery
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormTextInput
                      control={control}
                      name="leftOvery"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={8}>
                    <Typography align="left" variant="h4" padding={1}>
                      Tubes
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormTextInput
                      control={control}
                      name="tubes"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={8}>
                    <Typography align="left" variant="h4" padding={1}>
                      Tubal Patency Test
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormTextInput
                      control={control}
                      name="tubalPatencyTest"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography align="left" variant="h4" padding={1}>
                Upload Photo
              </Typography>
              <UploadPhoto name="laproscopy" takePhotoList={takePhotoList} />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                type="submit"
                loadingPosition="end"
                endIcon={<SaveIcon />}
              >
                Submit
              </LoadingButton>
              <Button variant="outlined" onClick={handleCancle}>
                cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </>
  );
};

export default LaproScopyForm;
