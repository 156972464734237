import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useAdditionalTreatmentService = () => {
  const axiosAuth = useAxiosAuth();

  const getAdditionalTreatmentByAppointmentId = async (id) => {
    try {
      const response = await axiosAuth.get(
        `/api/AdditionalTreatments/by-appointment-Id?appointmentId=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getAdditionalTreatmentByPatientId = async (patientId) => {
    try {
      const response = await axiosAuth.get(
        `/api/AdditionalTreatments/by-patient-id?patientId=${patientId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getadditionalTreatmentListByPatientAndStatus = async (id, status) => {
    try {
      const response = await axiosAuth.get(
        `/api/AdditionalTreatments/filtered-list?patientId=${id}&status=${status}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const statusUpdateAdditionalTreatments = async (
    id,
    status,
    userId,
    amount
  ) => {
    try {
      const response = await axiosAuth.put(
        `/api/AdditionalTreatments/update-status?id=${id}&status=${status}&userId=${userId}&amount=${amount}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const commentUpdates = async (additionaltreatmentId, comment) => {
    try {
      const response = await axiosAuth.put(
        `/api/AdditionalTreatments/update-comment?id=${additionaltreatmentId}&comment=${comment}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError;
    }
  };

  const getAdditionalTreatmentCountByPatient = async (status) => {
    try {
      // if (status == undefined) {
      //   const response = await axiosAuth.get(
      //     `/api/AdditionalTreatments/filtered-list`
      //   );
      //   return handleResponse(response);
      // } else {
      const response = await axiosAuth.get(
        `/api/AdditionalTreatments/countby-patient?status=${status}`
      );
      return handleResponse(response);
      // }
    } catch (error) {
      return handleError(error);
    }
  };
  const addAdditionalTreatment = async (values) => {
    try {
      const response = await axiosAuth.post(
        `/api/AdditionalTreatments`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const addSingleAdditionalTreatment = async (values) => {
    try {
      const response = await axiosAuth.post(
        `/api/AdditionalTreatments/create-single`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const updateAdditionalTreatment = async (id, values) => {
    try {
      const response = await axiosAuth.put(
        `/api/AdditionalTreatments?id=${id}`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const deleteAdditionalTreatment = async (id) => {
    try {
      const response = await axiosAuth.delete(
        `/api/AdditionalTreatments/delete?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    getAdditionalTreatmentByAppointmentId,
    getAdditionalTreatmentByPatientId,
    getAdditionalTreatmentCountByPatient,
    getadditionalTreatmentListByPatientAndStatus,
    statusUpdateAdditionalTreatments,
    commentUpdates,
    addAdditionalTreatment,
    addSingleAdditionalTreatment,
    updateAdditionalTreatment,
    deleteAdditionalTreatment,
  };
};

export default useAdditionalTreatmentService;
