import React from "react";
import { Grid, Typography } from "@mui/material";
import NoPhotos from "../../assets/images/nophotos.jpg";

const NoPhotosComponent = () => {
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        sx={{
          mt: 5,
          mb: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={NoPhotos} alt="No Result" width="150px" />
        <Typography variant="h4" fontWeight={100}>
          There are no photos uploaded for this patient!
        </Typography>
      </Grid>
    </>
  );
};

export default NoPhotosComponent;
