import React, { useContext } from "react";
import PrintReportContext from "./ReportContext";
import {
  Grid,
  Box,
  Typography,
  Card,
  useTheme,
  CardHeader,
  Stack,
  ButtonGroup,
  Tooltip,
  Button,
  Divider,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import ReportSingleComponent from "./ReportSingleComponent";

const ReportCardData = ({ id }) => {
  const {
    reportdata,
    reportName,
    reportId,
    doctorsdata,
    patientname,
    theme,
    isSmallScreen,
  } = useContext(PrintReportContext);
  return (
    <>
      <Grid container spacing={2}>
        <ReportSingleComponent
          id={id}
          label="impression"
          data={reportdata?.impression}
        />
        <ReportSingleComponent
          id={id}
          label="impression"
          data={reportdata?.impression}
        />
        <ReportSingleComponent
          id={id}
          label="impressionComment"
          data={reportdata?.impressionComment}
        />
        <ReportSingleComponent
          id={id}
          label="Pregnancy"
          data={reportdata?.pregnancyDisplay}
        />
        <ReportSingleComponent
          id={id}
          label=" Lie&Presentation"
          data={reportdata?.lieAndPresentationDisplay}
        />
        <ReportSingleComponent
          id={id}
          label="Fetal Movement"
          data={reportdata?.fetalMovementDisplay}
        />
        <ReportSingleComponent
          id={id}
          label="Fetal Cardiac Activity"
          data={reportdata?.fetalCardiacActivity}
        />
        <ReportSingleComponent
          id={id}
          label="fetal Cardiac Activity Value"
          data={reportdata?.fetalCardiacActivityValue}
        />
        <ReportSingleComponent id={id} label="hc" data={reportdata?.hc} />
        <ReportSingleComponent id={id} label="bpd" data={reportdata?.bpd} />
        <ReportSingleComponent id={id} label="ac" data={reportdata?.ac} />
        <ReportSingleComponent
          id={id}
          label="Femur Length"
          data={reportdata?.femurLength}
        />
        <ReportSingleComponent
          id={id}
          label="Estimated Fetal Weight"
          data={reportdata?.estimatedFetalWeight}
        />
        <ReportSingleComponent
          id={id}
          label="Liquor"
          data={reportdata?.liquorDisplay}
        />
        <ReportSingleComponent
          id={id}
          label="Placenta"
          data={reportdata?.placentaDisplay}
        />
        <ReportSingleComponent
          id={id}
          label="Avg Ultrasound Age Month"
          data={reportdata?.avgUltrasoundAgeMonth}
        />{" "}
        <ReportSingleComponent
          id={id}
          label="Avg Ultrasound Age Days"
          data={reportdata?.avgUltrasoundAgeDays}
        />{" "}
        <ReportSingleComponent
          id={id}
          label="eddByUSG"
          data={new Date(reportdata?.eddByUSG).toLocaleDateString("hi-IN")}
        />{" "}
        <ReportSingleComponent
          id={id}
          label="eddByLMP"
          data={new Date(reportdata?.eddByLMP).toLocaleDateString("hi-IN")}
        />
        <ReportSingleComponent
          id={id}
          label="gaByLMP"
          data={reportdata?.gaByLMP}
        />
        <ReportSingleComponent
          id={id}
          label="Cervix Length"
          data={reportdata?.cervixLength}
        />
        <ReportSingleComponent
          id={id}
          label="Scar Thickness"
          data={reportdata?.scarThickness}
        />
        <ReportSingleComponent
          id={id}
          label="Effacement Os Closed"
          data={reportdata?.effacementOsClosed}
        />
        <ReportSingleComponent
          id={id}
          label="Effacement Percentage"
          data={reportdata?.effacementPercentage}
        />
        <ReportSingleComponent
          id={id}
          label="Doppler Evaluation"
          data={reportdata?.dopplerEvaluationDisplay}
        />
        <ReportSingleComponent id={id} label="cpr" data={reportdata?.cpr} />
        <ReportSingleComponent
          id={id}
          label="fetal Umblical Artery PI"
          data={reportdata?.fetalUmblicalArteryPI}
        />
        <ReportSingleComponent
          id={id}
          label="fetal Umblical Artery RI"
          data={reportdata?.fetalUmblicalArteryRI}
        />
        <ReportSingleComponent
          id={id}
          label="fetalUmblicalArteryS_D"
          data={reportdata?.fetalUmblicalArteryS_D}
        />
        <ReportSingleComponent
          id={id}
          label="fetalMCAPI"
          data={reportdata?.fetalMCAPI}
        />
        <ReportSingleComponent
          id={id}
          label="fetalMCARI"
          data={reportdata?.fetalMCARI}
        />
        <ReportSingleComponent
          id={id}
          label="fetalMCAS_D"
          data={reportdata?.fetalMCAS_D}
        />
        <ReportSingleComponent
          id={id}
          label="rtUtArteryPI"
          data={reportdata?.rtUtArteryPI}
        />
        <ReportSingleComponent
          id={id}
          label="rtUtArteryRI"
          data={reportdata?.rtUtArteryRI}
        />
        <ReportSingleComponent
          id={id}
          label="ltUtArteryPI"
          data={reportdata?.ltUtArteryPI}
        />
        <ReportSingleComponent
          id={id}
          label="ltUtArteryRI"
          data={reportdata?.ltUtArteryRI}
        />
        <ReportSingleComponent
          id={id}
          label="utArtery"
          data={reportdata?.utArtery}
        />
        <ReportSingleComponent
          id={id}
          label="manningScore"
          data={reportdata?.manningScore}
        />
        <ReportSingleComponent
          id={id}
          label="manningScoreDisplay"
          data={reportdata?.manningScoreDisplay}
        />
        <ReportSingleComponent
          id={id}
          label="generalImpression"
          data={reportdata?.generalImpression}
        />
      </Grid>
    </>
  );
};

export default ReportCardData;
