import React, { memo, useCallback, useState, useEffect, Fragment } from "react";
import {
  Grid,
  Stack,
  Card,
  Typography,
  LinearProgress,
  Tooltip,
  IconButton,
  Snackbar,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import DeleteDialog from "./DeleteDialog";
import MuiAlert from "@mui/material/Alert";

import useGrowthUltraSoundScanPhotoService from "services/useGrowthultrasoundReportPhotoServices";
import AlertMessage from "ui-component/AlertMessage";
import useSonographyReportPhotoService from "services/useSonographyReportPhoto";
import useViabilityReportPhotoService from "services/useViabilityReportPhotos";

//ReportName COntext
import ReportNameContext, {
  ReportContextProvider,
} from "./ExternalReportContext";
import PrintREport from "./report-print";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ReportCard = ({ reportData, loadData }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedReport, setSelectedReport] = useState();
  const [reportName, setReportName] = useState();

  const [openDelete, setOpenDelete] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [reportFile, setReportFile] = useState();
  const [opentoast, setOpenToast] = useState(false);

  const { addGrowthAltraSoundScanReportPhoto } =
    useGrowthUltraSoundScanPhotoService();
  const { addSonographyReportPhoto } = useSonographyReportPhotoService();
  const { addViabilityReportPhoto } = useViabilityReportPhotoService();
  useEffect(() => {
    if (reportFile == undefined) {
      return;
    }

    if (selectedReport && reportFile && reportName) {
      const fd = new FormData();
      setUploading(true);
      fd.append("file", reportFile);
      if (reportName.toLowerCase() == "sonography") {
        addSonographyReportPhoto(selectedReport?.id, fd)
          .then((response) => {
            if (response.status === 200) {
              setOpenToast(true);
              setUploading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setUploading(false);
          });
      } else if (reportName.toLowerCase() == "viability") {
        console.log("fd", fd);
        addViabilityReportPhoto(selectedReport?.id, fd)
          .then((response) => {
            if (response.status === 200) {
              setOpenToast(true);
              setUploading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setUploading(false);
          });
      } else if (reportName.toLowerCase().split(" ")[0] == "growth") {
        addGrowthAltraSoundScanReportPhoto(selectedReport?.id, fd)
          .then((response) => {
            if (response.status === 200) {
              setOpenToast(true);
              setUploading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setUploading(false);
          });
      }
    }
  }, [reportFile]);
  const handleCancelDelete = () => {
    setOpenDelete(false);
  };
  const handleEdit = (data, name) => {
    if (!name) {
      return;
    }
    if (name.toLowerCase() === "sonography") {
      navigate(`/sonography-form?reportid=${data.id}`);
    }
    if (name.toLowerCase() === "viability") {
      navigate(`/viability-form?reportid=${data.id}`);
    }
    if (name.toLowerCase().split(" ")[0] === "growth") {
      navigate(`/growthultrasound-form?reportid=${data.id}`);
    }
  };
  const handleCloseToast = () => {
    setOpenToast(false);
  };
  const handleUpload = (data, name) => {
    setReportName(name);
    setSelectedReport(data);
  };
  const viewReport = (id, reportname) => {
    if (!id && !reportname) {
      return;
    }
    window.open(`/print-report?reportid=${id}&name=${reportname}`, "_blank");
  };
  return (
    <>
      <Grid container spacing={2} marginTop={5}>
        {reportData.map((row, index) => (
          <Fragment key={index}>
            {Array.isArray(row.data) &&
              row.data.map((d, i) => (
                <Grid item xs={6} md={4}>
                  <Card
                    variant="outlined"
                    sx={{
                      padding: 2,
                      backgroundColor: theme.palette.primary.light,
                    }}
                  >
                    <Stack spacing={2}>
                      <Stack spacing={1}>
                        <Typography fontWeight="bold">
                          {row?.name} Report
                        </Typography>
                      </Stack>
                      <Stack spacing={1}>
                        <Typography fontWeight="bold">Report Date</Typography>
                        <Typography>
                          {new Date(d.date).toLocaleDateString("hi-IN")}{" "}
                        </Typography>
                      </Stack>
                      {uploading &&
                      d?.id == selectedReport?.id &&
                      reportName.toLowerCase() == row?.name.toLowerCase() ? (
                        <Stack spacing={1}>
                          <Typography>Uploading file... </Typography>
                          <LinearProgress />
                        </Stack>
                      ) : (
                        <Stack
                          spacing={1}
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <>
                            <label htmlFor="btn-upload">
                              <input
                                id="btn-upload"
                                name="btn-upload"
                                style={{ display: "none" }}
                                type="file"
                                multiple={false}
                                accept="application/pdf, image/png, image/jpg, image/jpeg"
                                onChange={(e) => {
                                  // setSelectedReport(d);
                                  setReportFile(e?.target?.files[0]);
                                }}
                              />

                              <Tooltip
                                title={
                                  d?.isFileUploaded
                                    ? "Change Report File"
                                    : "Upload Report"
                                }
                              >
                                <IconButton component="span" color="primary">
                                  <UploadFileIcon
                                    onClick={() => {
                                      handleUpload(d, row?.name);
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </label>
                            <Tooltip title="View Report">
                              <IconButton
                                // disabled={data.isFileUploaded == false ? true : false}
                                onClick={() => {
                                  viewReport(d.id, row.name);
                                }}
                                color="primary"
                              >
                                <OpenInNewIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit Report Details">
                              <IconButton
                                onClick={() => {
                                  handleEdit(d, row.name);
                                }}
                                color="primary"
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => {
                                  setSelectedReport(d);
                                  setReportName(row.name);
                                  setOpenDelete(true);
                                }}
                                color="error"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        </Stack>
                      )}
                    </Stack>
                  </Card>
                </Grid>
              ))}
          </Fragment>
        ))}
      </Grid>
      <AlertMessage message="Report Uploaded succesfully!!" />
      <DeleteDialog
        openDelete={openDelete}
        handleCancelDelete={handleCancelDelete}
        selectedReport={selectedReport}
        reportName={reportName}
        loadData={loadData}
        componentname="card"
      />
    </>
  );
};

export default memo(ReportCard);
